// USER PASSWORD CHANGE
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCSESS = "USER_DETAILS_SUCSESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

// USER PASSWORD CHANGE
export const HISTORY_DATA_REQUEST = "HISTORY_DATA_REQUEST";
export const HISTORY_DATA_SUCCESS = "HISTORY_DATA_SUCCESS";
export const HISTORY_DATA_FAIL = "HISTORY_DATA_FAIL";



// USER PASSWORD CHANGE
export const POST_ADD_EXPERIENCE_REQUEST = "POST_ADD_EXPERIENCE_REQUEST";
export const POST_ADD_EXPERIENCE_SUCCESS = "POST_ADD_EXPERIENCE_SUCCESS";
export const POST_ADD_EXPERIENCE_FAIL = "POST_ADD_EXPERIENCE_FAIL";


// USER PASSWORD CHANGE
export const GET_USER_PROFILE_DATA_REQUEST = "GET_USER_PROFILE_DATA_REQUEST";
export const GET_USER_PROFILE_DATA_SUCCESS = "GET_USER_PROFILE_DATA_SUCCESS";
export const GET_USER_PROFILE_DATA_FAIL = "GET_USER_PROFILE_DATA_FAIL";



// GET_SINGLE_EXPERIENCE_OF_USER_REQUEST
export const GET_SINGLE_EXPERIENCE_OF_USER_REQUEST = "GET_SINGLE_EXPERIENCE_OF_USER_REQUEST";
export const GET_SINGLE_EXPERIENCE_OF_USER_SUCCESS = "GET_SINGLE_EXPERIENCE_OF_USER_SUCCESS";
export const GET_SINGLE_EXPERIENCE_OF_USER_FAIL = "GET_SINGLE_EXPERIENCE_OF_USER_FAIL";



// PUT_USER_EXPERIENCE_DATA_REQUEST
export const PUT_USER_EXPERIENCE_DATA_REQUEST = "PUT_USER_EXPERIENCE_DATA_REQUEST";
export const PUT_USER_EXPERIENCE_DATA_SUCCESS = "PUT_USER_EXPERIENCE_DATA_SUCCESS";
export const PUT_USER_EXPERIENCE_DATA_FAIL = "PUT_USER_EXPERIENCE_DATA_FAIL";


// PUT_END_ASSOCIATIN_SUCCESS
export const PUT_END_ASSOCIATIN_REQUEST = "PUT_END_ASSOCIATIN_REQUEST";
export const PUT_END_ASSOCIATIN_SUCCESS = "PUT_END_ASSOCIATIN_SUCCESS";
export const PUT_END_ASSOCIATIN_FAIL = "PUT_END_ASSOCIATIN_FAIL";