// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBarForAll_logoText__kPdox {
    color: #fff;
    font-size: 1.2rem;
}

.NavBarForAll_header__wcxZb {
    position: fixed;
    top: 0;
    z-index: 50;
    width: 100%;
    background: #fff;
}
.NavBarForAll_container__uuZL- {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem; /* Equivalent to px-3 and py-3 */
    background-color: #98004c;
}

@media (min-width: 1024px) {
    .NavBarForAll_container__uuZL- {
        padding-left: 0.625rem; /* Equivalent to lg:pl-3 */
        padding-right: 1.25rem; /* Equivalent to lg:px-5 */
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/NavBarForAll.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,WAAW;IACX,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB,EAAE,gCAAgC;IAClD,yBAAyB;AAC7B;;AAEA;IACI;QACI,sBAAsB,EAAE,0BAA0B;QAClD,sBAAsB,EAAE,0BAA0B;IACtD;AACJ","sourcesContent":[".logoText {\n    color: #fff;\n    font-size: 1.2rem;\n}\n\n.header {\n    position: fixed;\n    top: 0;\n    z-index: 50;\n    width: 100%;\n    background: #fff;\n}\n.container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0.75rem; /* Equivalent to px-3 and py-3 */\n    background-color: #98004c;\n}\n\n@media (min-width: 1024px) {\n    .container {\n        padding-left: 0.625rem; /* Equivalent to lg:pl-3 */\n        padding-right: 1.25rem; /* Equivalent to lg:px-5 */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoText": `NavBarForAll_logoText__kPdox`,
	"header": `NavBarForAll_header__wcxZb`,
	"container": `NavBarForAll_container__uuZL-`
};
export default ___CSS_LOADER_EXPORT___;
