// USER DETAILS
export const ALL_USERS_DETAILS_REQUEST = 'ALL_USERS_DETAILS_REQUEST'
export const ALL_USERS_DETAILS_SUCCESS = 'ALL_USERS_DETAILS_SUCCESS'
export const ALL_USERS_DETAILS_FAIL = 'ALL_USERS_DETAILS_FAIL'

// USER UPDATE
export const UPDATE_ALL_USERS_REQUEST = 'UPDATE_ALL_USERS_REQUEST'
export const UPDATE_ALL_USERS_SUCCESS = 'UPDATE_ALL_USERS_SUCCESS'
export const UPDATE_ALL_USERS_FAIL = 'UPDATE_ALL_USERS_FAIL'

// Dashboard Data 
export const ALL_DEFAULT_DATA_FOR_DASHBOARD_REQUEST = 'ALL_DEFAULT_DATA_FOR_DASHBOARD_REQUEST'
export const ALL_DEFAULT_DATA_FOR_DASHBOARD_SUCCESS = 'ALL_DEFAULT_DATA_FOR_DASHBOARD_SUCCESS'
export const ALL_DEFAULT_DATA_FOR_DASHBOARD_FAIL = 'ALL_DEFAULT_DATA_FOR_DASHBOARD_FAIL'

// Dashboard Data 
export const DEVICE_ACTION_REQUEST = 'DEVICE_ACTION_REQUEST'
export const DEVICE_ACTION_SUCCESS = 'DEVICE_ACTION_SUCCESS'
export const DEVICE_ACTION_FAIL = 'DEVICE_ACTION_FAIL'

// Dashboard Data 
export const DEVICE_DELETE_REQUEST = 'DEVICE_DELETE_REQUEST'
export const DEVICE_DELETE_SUCCESS = 'DEVICE_DELETE_SUCCESS'
export const DEVICE_DELETE_FAIL = 'DEVICE_DELETE_FAIL'

// ASSIGN_DELETE_DATA_ACTION_REQUEST
export const DEVICE_DELETE_DATA_ACTION_REQUEST = 'DEVICE_DELETE_DATA_ACTION_REQUEST'
export const DEVICE_DELETE_DATA_ACTION_SUCCESS = 'DEVICE_DELETE_DATA_ACTION_SUCCESS'
export const DEVICE_DELETE_DATA_ACTION_FAIL = 'DEVICE_DELETE_DATA_ACTION_FAIL'

// USER_DELETE_DATA_ACTION_REQUEST
export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_REQUEST_SUCCESS = 'USER_DELETE_REQUEST_SUCCESS'
export const USER_DELETE_REQUEST_FAIL = 'USER_DELETE_REQUEST_FAIL'

// ALL_ACTIVE_USERS_REQUEST
export const ALL_ACTIVE_USERS_REQUEST = 'ALL_ACTIVE_USERS_REQUEST'
export const ALL_ACTIVE_USERS_SUCCESS = 'ALL_ACTIVE_USERS_SUCCESS'
export const ALL_ACTIVE_USERS_FAIL = 'ALL_ACTIVE_USERS_FAIL'

// ALL_PENDING_USERS_REQUEST
export const ALL_PENDING_USERS_REQUEST = 'ALL_PENDING_USERS_REQUEST'
export const ALL_PENDING_USERS_SUCCESS = 'ALL_PENDING_USERS_SUCCESS'
export const ALL_PENDING_USERS_FAIL = 'ALL_PENDING_USERS_FAIL'

// ALL_PENDING_USERS_REQUEST
export const PUT_APP_DISAPPROVE_PENDING_REQUEST = 'PUT_APP_DISAPPROVE_PENDING_REQUEST'
export const PUT_APP_DISAPPROVE_PENDING_SUCCESS = 'PUT_APP_DISAPPROVE_PENDING_SUCCESS'
export const PUT_APP_DISAPPROVE_PENDING_FAIL = 'PUT_APP_DISAPPROVE_PENDING_FAIL'

// ALL_PENDING_USERS_REQUEST
export const GET_HOSPITAL_ADMIN_USER_REQUEST = 'GET_HOSPITAL_ADMIN_USER_REQUEST'
export const GET_HOSPITAL_ADMIN_USER_SUCCESS = 'GET_HOSPITAL_ADMIN_USER_SUCCESS'
export const GET_HOSPITAL_ADMIN_USER_FAIL = 'GET_HOSPITAL_ADMIN_USER_FAIL'

// GET_USERDATA_BY_DEVICEiD_REQUEST
export const GET_USERDATA_BY_DEVICEiD_REQUEST = 'GET_USERDATA_BY_DEVICEiD_REQUEST'
export const GET_USERDATA_BY_DEVICEiD_SUCCESS = 'GET_USERDATA_BY_DEVICEiD_SUCCESS'
export const GET_USERDATA_BY_DEVICEiD_FAIL = 'GET_USERDATA_BY_DEVICEiD_FAIL'

// DELETE_USER_FROM_ASSIGNED_REQUEST_REQUEST
export const DELETE_USER_FROM_ASSIGNED_REQUEST_REQUEST = 'DELETE_USER_FROM_ASSIGNED_REQUEST_REQUEST'
export const DELETE_USER_FROM_ASSIGNED_REQUEST_SUCCESS = 'DELETE_USER_FROM_ASSIGNED_REQUEST_SUCCESS'
export const DELETE_USER_FROM_ASSIGNED_REQUEST_FAIL = 'DELETE_USER_FROM_ASSIGNED_REQUEST_FAIL'

// DELETE_USER_FROM_ASSIGNED_REQUEST_REQUEST
export const GET_ACCESS_DATA_ACTION_REQUEST = 'GET_ACCESS_DATA_ACTION_REQUEST'
export const GET_ACCESS_DATA_ACTION_SUCCESS = 'GET_ACCESS_DATA_ACTION_SUCCESS'
export const GET_ACCESS_DATA_ACTION_FAIL = 'GET_ACCESS_DATA_ACTION_FAIL'

// GET_ACCESS_REVIEW_DATA_ACTION_REQUEST
export const GET_ACCESS_REVIEW_DATA_ACTION_REQUEST = 'GET_ACCESS_REVIEW_DATA_ACTION_REQUEST'
export const GET_ACCESS_REVIEW_DATA_ACTION_SUCCESS = 'GET_ACCESS_REVIEW_DATA_ACTION_SUCCESS'
export const GET_ACCESS_REVIEW_DATA_ACTION_FAIL = 'GET_ACCESS_REVIEW_DATA_ACTION_FAIL'

// GET_ACTIVE_ADMIN_REQUEST
export const GET_ACTIVE_ADMIN_REQUEST = 'GET_ACTIVE_ADMIN_REQUEST'
export const GET_ACTIVE_ADMIN_SUCCESS = 'GET_ACTIVE_ADMIN_SUCCESS'
export const GET_ACTIVE_ADMIN_FAIL = 'GET_ACTIVE_ADMIN_FAIL'

// POST_REQUEST_ACTION_BY_HOSPITAL_ADMIN_REQUEST
export const POST_REQUEST_ACTION_BY_HOSPITAL_ADMIN_REQUEST = 'POST_REQUEST_ACTION_BY_HOSPITAL_ADMIN_REQUEST'
export const POST_REQUEST_ACTION_BY_HOSPITAL_ADMIN_SUCCESS = 'POST_REQUEST_ACTION_BY_HOSPITAL_ADMIN_SUCCESS'
export const POST_REQUEST_ACTION_BY_HOSPITAL_ADMIN_FAIL = 'POST_REQUEST_ACTION_BY_HOSPITAL_ADMIN_FAIL'

// GET_EMPLOYEE_LIST_REQUEST
export const GET_EMPLOYEE_LIST_REQUEST = 'GET_EMPLOYEE_LIST_REQUEST'
export const GET_EMPLOYEE_LIST_SUCCCESS = 'GET_EMPLOYEE_LIST_SUCCCESS'
export const GET_EMPLOYEE_LIST_FAIL = 'GET_EMPLOYEE_LIST_FAIL'

// GET_SOLD_DEMO_DATA_REQUEST
export const GET_SOLD_DEMO_DATA_REQUEST = 'GET_SOLD_DEMO_DATA_REQUEST'
export const GET_SOLD_DEMO_DATA_SUCCESS = 'GET_SOLD_DEMO_DATA_SUCCESS'
export const GET_SOLD_DEMO_DATA_FAIL = 'GET_SOLD_DEMO_DATA_FAIL'

// GET_ASSISTANT_REQUEST
export const GET_ASSISTANT_REQUEST = 'GET_ASSISTANT_REQUEST'
export const GET_ASSISTANT_SUCCESS = 'GET_ASSISTANT_SUCCESS'
export const GET_ASSISTANT_FAIL = 'GET_ASSISTANT_FAIL'


// ALL_INACTIVE_USERS_REQUEST
export const ALL_INACTIVE_USERS_REQUEST = 'ALL_INACTIVE_USERS_REQUEST'
export const ALL_INACTIVE_USERS_SUCCESS = 'ALL_INACTIVE_USERS_SUCCESS'
export const ALL_INACTIVE_USERS_FAIL = 'ALL_INACTIVE_USERS_FAIL'


// GET_DOCTOR_ACCESS_LIST_REQUEST
export const GET_DOCTOR_ACCESS_LIST_REQUEST = 'GET_DOCTOR_ACCESS_LIST_REQUEST'
export const GET_DOCTOR_ACCESS_LIST_SUCCESS = 'GET_DOCTOR_ACCESS_LIST_SUCCESS'
export const GET_DOCTOR_ACCESS_LIST_FAIL = 'GET_DOCTOR_ACCESS_LIST_FAIL'

// REMOVE_DOCTOR_ACCESS_LIST_REQUEST
export const REMOVE_DOCTOR_ACCESS_LIST_REQUEST = 'REMOVE_DOCTOR_ACCESS_LIST_REQUEST'
export const REMOVE_DOCTOR_ACCESS_LIST_SUCCESS = 'REMOVE_DOCTOR_ACCESS_LIST_SUCCESS'
export const REMOVE_DOCTOR_ACCESS_LIST_FAIL = 'REMOVE_DOCTOR_ACCESS_LIST_FAIL'

// GET_HOSPITAL_LIST_BY_ASSISTANT_REQUEST
export const GET_HOSPITAL_LIST_BY_ASSISTANT_REQUEST = 'GET_HOSPITAL_LIST_BY_ASSISTANT_REQUEST'
export const GET_HOSPITAL_LIST_BY_ASSISTANT_SUCCESS = 'GET_HOSPITAL_LIST_BY_ASSISTANT_SUCCESS'
export const GET_HOSPITAL_LIST_BY_ASSISTANT_FAIL = 'GET_HOSPITAL_LIST_BY_ASSISTANT_FAIL'

// ASSISTANT_REMOVE_FROM_HOSPITAL_REQUEST
export const ASSISTANT_REMOVE_FROM_HOSPITAL_REQUEST = 'ASSISTANT_REMOVE_FROM_HOSPITAL_REQUEST'
export const ASSISTANT_REMOVE_FROM_HOSPITAL_SUCCESS = 'ASSISTANT_REMOVE_FROM_HOSPITAL_SUCCESS'
export const ASSISTANT_REMOVE_FROM_HOSPITAL_FAIL = 'ASSISTANT_REMOVE_FROM_HOSPITAL_FAIL'

// ASSISTANT_REMOVE_FROM_HOSPITAL_REQUEST
export const GET_DEVICE_COUNT_FOR_VENTILATOR_REQUEST = 'GET_DEVICE_COUNT_FOR_VENTILATOR_REQUEST'
export const GET_DEVICE_COUNT_FOR_VENTILATOR_SUCCESS = 'GET_DEVICE_COUNT_FOR_VENTILATOR_SUCCESS'
export const GET_DEVICE_COUNT_FOR_VENTILATOR_FAIL = 'GET_DEVICE_COUNT_FOR_VENTILATOR_FAIL'

// GET_DEMO_DEVICES_REQUEST
export const GET_DEMO_DEVICES_REQUEST = 'GET_DEMO_DEVICES_REQUEST'
export const GET_DEMO_DEVICES_SUCCESS = 'GET_DEMO_DEVICES_SUCCESS'
export const GET_DEMO_DEVICES_FAIL = 'GET_DEMO_DEVICES_FAIL'

// GET_ACTIVE_DEMO_DEVICE_REQUEST
export const GET_ACTIVE_DEVICE_REQUEST = 'GET_ACTIVE_DEVICE_REQUEST'
export const GET_ACTIVE_DEVICE_SUCCESS = 'GET_ACTIVE_DEVICE_SUCCESS'
export const GET_ACTIVE_DEVICE_FAIL = 'GET_ACTIVE_DEVICE_FAIL'

// GET_ACTIVE_DEMO_DEVICE_REQUEST
export const GET_ACTIVE_DEMO_DEVICES_REQUEST = 'GET_ACTIVE_DEMO_DEVICES_REQUEST'
export const GET_ACTIVE_DEMO_DEVICES_SUCCESS = 'GET_ACTIVE_DEMO_DEVICES_SUCCESS'
export const GET_ACTIVE_DEMO_DEVICES_FAIL = 'GET_ACTIVE_DEMO_DEVICES_FAIL'

// GET_ACTIVE_DEMO_DEVICE_REQUEST
export const GET_ALL_ACCESS_DEVICE_TO_USER_ACTION = 'GET_ALL_ACCESS_DEVICE_TO_USER_ACTION'
export const GET_ALL_ACCESS_DEVICE_TO_USER_SUCCESS = 'GET_ALL_ACCESS_DEVICE_TO_USER_SUCCESS'
export const GET_ALL_ACCESS_DEVICE_TO_USER_FAIL = 'GET_ALL_ACCESS_DEVICE_TO_USER_FAIL'


// REMOVE_ALL_ACCESS_LIST_ACTION
export const REMOVE_ALL_ACCESS_LIST_ACTION = 'REMOVE_ALL_ACCESS_LIST_ACTION'
export const REMOVE_ALL_ACCESS_LIST_SUCCESS = 'REMOVE_ALL_ACCESS_LIST_SUCCESS'
export const REMOVE_ALL_ACCESS_LIST_FAIL = 'REMOVE_ALL_ACCESS_LIST_FAIL'

// GET_DASHBOARD_GRAPH_DATA_ACTION
export const GET_DASHBOARD_GRAPH_DATA_ACTION = 'GET_DASHBOARD_GRAPH_DATA_ACTION'
export const GET_DASHBOARD_GRAPH_DATA_SUCCESS = 'GET_DASHBOARD_GRAPH_DATA_SUCCESS'
export const GET_DASHBOARD_GRAPH_DATA_FAIL = 'GET_DASHBOARD_GRAPH_DATA_FAIL'