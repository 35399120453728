// ADMIN LOGIN
export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST'
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS'
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL'

// ADMIN LOGOUT
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'
export const ADMIN_LOGOUT_FAIL = 'ADMIN_LOGOUT_FAIL'

// ADMIN REGISTER
export const ADMIN_REGISTER_REQUEST = "ADMIN_REGISTER_REQUEST"
export const ADMIN_REGISTER_SUCCESS = "ADMIN_REGISTER_SUCCESS"
export const ADMIN_REGISTER_FAIL = "ADMIN_REGISTER_FAIL"

// ADMIN PASSWORD FORGET
export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST'
export const FORGET_PASSWORD_REQUEST_SUCCESS = 'FORGET_PASSWORD_REQUEST_SUCCESS'
export const FORGET_PASSWORD_REQUEST_FAIL = 'FORGET_PASSWORD_REQUEST_FAIL'
export const FORGET_PASSWORD_RESET_STATE = 'FORGET_PASSWORD_RESET_STATE'

// ADMIN PASSWORD RESET
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS'
export const RESET_PASSWORD_REQUEST_FAIL = 'RESET_PASSWORD_REQUEST_FAIL'

// ADMIN UPDATE PROFILE
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_REQUEST_SUCCESS = 'UPDATE_PROFILE_REQUEST_SUCCESS'
export const UPDATE_PROFILE_REQUEST_FAIL = 'UPDATE_PROFILE_REQUEST_FAIL'

// HOSPITAL NAME DATA
export const HOSPITAL_NAME_REQUEST = 'HOSPITAL_NAME_REQUEST'
export const HOSPITAL_NAME_SUCCESS = 'HOSPITAL_NAME_SUCCESS'
export const HOSPITAL_NAME_FAIL = 'HOSPITAL_NAME_FAIL'

// COUNTRY NAME DATA
export const COUNTRY_STATE_DATA_REQUEST = 'COUNTRY_STATE_DATA_REQUEST'
export const COUNTRY_STATE_DATA_SUCCESS = 'COUNTRY_STATE_DATA_SUCCESS'
export const COUNTRY_STATE_DATA_FAIL = 'COUNTRY_STATE_DATA_FAIL'

// STATE NAME DATA
export const STATE_DATA_REQUEST = 'STATE_DATA_REQUEST'
export const STATE_DATA_SUCCESS = 'STATE_DATA_SUCCESS'
export const STATE_DATA_FAIL = 'STATE_DATA_FAIL'


// GET_OTP_ON_NUMBER_REQUEST
export const GET_OTP_ON_NUMBER_REQUEST = 'GET_OTP_ON_NUMBER_REQUEST'
export const GET_OTP_ON_NUMBER_SUCCESS = 'GET_OTP_ON_NUMBER_SUCCESS'
export const GET_OTP_ON_NUMBER_FAIL = 'GET_OTP_ON_NUMBER_FAIL'

// VERIFY_SMS_OTP_NUMBER_REQUEST
export const VERIFY_SMS_OTP_NUMBER_REQUEST = 'VERIFY_SMS_OTP_NUMBER_REQUEST'
export const VERIFY_SMS_OTP_NUMBER_SUCCESS = 'VERIFY_SMS_OTP_NUMBER_SUCCESS'
export const VERIFY_SMS_OTP_NUMBER_FAIL = 'VERIFY_SMS_OTP_NUMBER_FAIL'

// ADMIN_REGISTER_FOR_OTHER_ROLES_REQUEST
export const ADMIN_REGISTER_FOR_OTHER_ROLES_REQUEST = 'ADMIN_REGISTER_FOR_OTHER_ROLES_REQUEST'
export const ADMIN_REGISTER_FOR_OTHER_ROLES_SUCCESS = 'ADMIN_REGISTER_FOR_OTHER_ROLES_SUCCESS'
export const ADMIN_REGISTER_FOR_OTHER_ROLES_FAIL = 'ADMIN_REGISTER_FOR_OTHER_ROLES_FAIL'
