// GET ALL TICKETS DATA
export const GET_ALL_TICKETS_DATA_REQUEST = "GET_ALL_TICKETS_DATA_REQUEST"
export const GET_ALL_TICKETS_DATA_SUCCESS = "GET_ALL_TICKETS_DATA_SUCCESS"
export const GET_ALL_TICKETS_DATA_FAIL = "GET_ALL_TICKETS_DATA_FAIL"

// PUT OR UPDATE STATUS DATA
export const UPDATE_STATUS_DATA_REQUEST = "UPDATE_STATUS_DATA_REQUEST"
export const UPDATE_STATUS_DATA_SUCCESS = "UPDATE_STATUS_DATA_SUCCESS"
export const UPDATE_STATUS_DATA_FAIL = "UPDATE_STATUS_DATA_FAIL"

// DELETE OR UPDATE STATUS DATA
export const DELETE_STATUS_DATA_REQUEST = "DELETE_STATUS_DATA_REQUEST"
export const DELETE_STATUS_DATA_SUCCESS = "DELETE_STATUS_DATA_SUCCESS"
export const DELETE_STATUS_DATA_FAIL = "DELETE_STATUS_DATA_FAIL"

// TICKET DETAILS BY ID 
export const GET_TICKET_DETAILS_BY_ID_REQUEST = "GET_TICKET_DETAILS_BY_ID_REQUEST"
export const GET_TICKET_DETAILS_BY_ID_SUCCESS = "GET_TICKET_DETAILS_BY_ID_SUCCESS"
export const GET_TICKET_DETAILS_BY_ID_FAIL = "GET_TICKET_DETAILS_BY_ID_FAIL"

// UPLOAD DOCX 
export const POST_DOC_BY_SERVICE_REQUEST = "POST_DOC_BY_SERVICE_REQUEST"
export const POST_DOC_BY_SERVICE_SUCCESS = "POST_DOC_BY_SERVICE_SUCCESS"
export const POST_DOC_BY_SERVICE_FAIL = "POST_DOC_BY_SERVICE_FAIL"

// INSTALATION REPORT DATA 
export const INSTALLATION_REPORT_REQUEST = "INSTALLATION_REPORT_REQUEST"
export const INSTALLATION_REPORT_REQUEST_SUCCESS = "INSTALLATION_REPORT_REQUEST_SUCCESS"
export const INSTALLATION_REPORT_REQUEST_FAIL = "INSTALLATION_REPORT_REQUEST_FAIL"

// GET_TICKET DETAILS BY TICKET NO
export const GET_TICKET_DETAILS_BY_TICKET_NO_REQUEST = "GET_TICKET_DETAILS_BY_TICKET_NO_REQUEST"
export const GET_TICKET_DETAILS_BY_TICKET_NO_SUCCESS = "GET_TICKET_DETAILS_BY_TICKET_NO_SUCCESS"
export const GET_TICKET_DETAILS_BY_TICKET_NO_FAIL = "GET_TICKET_DETAILS_BY_TICKET_NO_FAIL"

// GET_SERVICE_ENGINNER_STATUS_REQUEST
export const GET_SERVICE_ENGINNER_STATUS_REQUEST = "GET_SERVICE_ENGINNER_STATUS_REQUEST"
export const GET_SERVICE_ENGINNER_STATUS_SUCCESS = "GET_SERVICE_ENGINNER_STATUS_SUCCESS"
export const GET_SERVICE_ENGINNER_STATUS_FAIL = "GET_SERVICE_ENGINNER_STATUS_FAIL"

// GET_SERVICE_ENGINNER_DATA_REQUEST
export const GET_SERVICE_ENGINNER_DATA_REQUEST = "GET_SERVICE_ENGINNER_DATA_REQUEST"
export const GET_SERVICE_ENGINNER_DATA_SUCCESS = "GET_SERVICE_ENGINNER_DATA_SUCCESS"
export const GET_SERVICE_ENGINNER_DATA_FAIL = "GET_SERVICE_ENGINNER_DATA_FAIL"

// GET_RE_ASSIGN_TICKETS_REQUEST
export const GET_RE_ASSIGN_TICKETS_REQUEST = "GET_RE_ASSIGN_TICKETS_REQUEST"
export const GET_RE_ASSIGN_TICKETS_SUCCESS = "GET_RE_ASSIGN_TICKETS_SUCCESS"
export const GET_RE_ASSIGN_TICKETS_FAIL = "GET_RE_ASSIGN_TICKETS_FAIL"

// GET_ALL_SERVICES_REQUEST
export const GET_ALL_SERVICES_REQUEST = "GET_ALL_SERVICES_REQUEST"
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS"
export const GET_ALL_SERVICES_FAIL = "GET_ALL_SERVICES_FAIL"

// GET_SINGLE_SERVICES_REQUEST
export const GET_SINGLE_SERVICES_REQUEST = "GET_SINGLE_SERVICES_REQUEST"
export const GET_SINGLE_SERVICES_SUCCESS = "GET_SINGLE_SERVICES_SUCCESS"
export const GET_SINGLE_SERVICES_FAIL = "GET_SINGLE_SERVICES_FAIL"

// GET_OTP_FOR_SERVICES_TICKET_REQUEST
export const GET_OTP_FOR_SERVICES_TICKET_REQUEST = "GET_OTP_FOR_SERVICES_TICKET_REQUEST"
export const GET_OTP_FOR_SERVICES_TICKET_SUCCESS = "GET_OTP_FOR_SERVICES_TICKET_SUCCESS"
export const GET_OTP_FOR_SERVICES_TICKET_FAIL = "GET_OTP_FOR_SERVICES_TICKET_FAIL"

// GET_VERIFY_OTP_SERIVES_REQUEST
export const GET_VERIFY_OTP_SERIVES_REQUEST = "GET_VERIFY_OTP_SERIVES_REQUEST"
export const GET_VERIFY_OTP_SERIVES_SUCCESS = "GET_VERIFY_OTP_SERIVES_SUCCESS"
export const GET_VERIFY_OTP_SERIVES_FAIL = "GET_VERIFY_OTP_SERIVES_FAIL"

// GET_VERIFY_OTP_SERIVES_REQUEST
export const POST_TICKET_STATUS_DATA_REQUEST = "POST_TICKET_STATUS_DATA_REQUEST"
export const POST_TICKET_STATUS_DATA_SUCCESS = "POST_TICKET_STATUS_DATA_SUCCESS"
export const POST_TICKET_STATUS_DATA_FAIL = "POST_TICKET_STATUS_DATA_FAIL"

// GET_EXPENCES_DATA_REQUEST
export const GET_EXPENCES_DATA_REQUEST = "GET_EXPENCES_DATA_REQUEST"
export const GET_EXPENCES_DATA_SUCCESS = "GET_EXPENCES_DATA_SUCCESS"
export const GET_EXPENCES_DATA_FAIL = "GET_EXPENCES_DATA_FAIL"

// GET_ALL_EXPENCE_DATA_REQUEST
export const GET_ALL_EXPENCE_DATA_REQUEST = "GET_ALL_EXPENCE_DATA_REQUEST"
export const GET_ALL_EXPENCE_DATA_SUCCESS = "GET_ALL_EXPENCE_DATA_SUCCESS"
export const GET_ALL_EXPENCE_DATA_FAIL = "GET_ALL_EXPENCE_DATA_FAIL"

// POST_STATUS_DATA_REQUEST
export const POST_STATUS_DATA_REQUEST = "POST_STATUS_DATA_REQUEST"
export const POST_STATUS_DATA_SUCCESS = "POST_STATUS_DATA_SUCCESS"
export const POST_STATUS_DATA_FAIL = "POST_STATUS_DATA_FAIL"

// GET_DEMO_DATA_REQUEST
export const GET_DEMO_DATA_REQUEST = "GET_DEMO_DATA_REQUEST"
export const GET_DEMO_DATA_SUCCESS = "GET_DEMO_DATA_SUCCESS"
export const GET_DEMO_DATA_FAIL = "GET_DEMO_DATA_FAIL"

// POST_DEMO_ADD_DATA_REQUEST
export const POST_DEMO_ADD_DATA_REQUEST = "POST_DEMO_ADD_DATA_REQUEST"
export const POST_DEMO_ADD_DATA_SUCCESS = "POST_DEMO_ADD_DATA_SUCCESS"
export const POST_DEMO_ADD_DATA_FAIL = "POST_DEMO_ADD_DATA_FAIL"

// GET_MILESTONE_COUNT_REQUEST
export const GET_MILESTONE_COUNT_REQUEST = "GET_MILESTONE_COUNT_REQUEST"
export const GET_MILESTONE_COUNT_SUCCESS = "GET_MILESTONE_COUNT_SUCCESS"
export const GET_MILESTONE_COUNT_FAIL = "GET_MILESTONE_COUNT_FAIL"

// GET_MARKETING_USER_REQUEST
export const GET_MARKETING_USER_REQUEST = "GET_MARKETING_USER_REQUEST"
export const GET_MARKETING_USER_SUCCESS = "GET_MARKETING_USER_SUCCESS"
export const GET_MARKETING_USER_FAIL = "GET_MARKETING_USER_FAIL"

// POST_USER_ASSIGN_MILESTONE_REQUEST
export const POST_USER_ASSIGN_MILESTONE_REQUEST = "POST_USER_ASSIGN_MILESTONE_REQUEST"
export const POST_USER_ASSIGN_MILESTONE_SUCCESS = "POST_USER_ASSIGN_MILESTONE_SUCCESS"
export const POST_USER_ASSIGN_MILESTONE_FAIL = "POST_USER_ASSIGN_MILESTONE_FAIL"

// GET_TOTAL_DATA_COUNT_REQUEST
export const GET_TOTAL_DATA_COUNT_REQUEST = "GET_TOTAL_DATA_COUNT_REQUEST"
export const GET_TOTAL_DATA_COUNT_SUCCESS = "GET_TOTAL_DATA_COUNT_SUCCESS"
export const GET_TOTAL_DATA_COUNT_FAIL = "GET_TOTAL_DATA_COUNT_FAIL"

// GET_SALES_DATA_REQUEST
export const GET_SALES_DATA_REQUEST = "GET_SALES_DATA_REQUEST"
export const GET_SALES_DATA_SUCCESS = "GET_SALES_DATA_SUCCESS"
export const GET_SALES_DATA_FAIL = "GET_SALES_DATA_FAIL"


// POST_DELETE_EXPENCES_LIST_REQUEST
export const POST_DELETE_EXPENCES_LIST_REQUEST = "POST_DELETE_EXPENCES_LIST_REQUEST"
export const POST_DELETE_EXPENCES_LIST_SUCCESS = "POST_DELETE_EXPENCES_LIST_SUCCESS"
export const POST_DELETE_EXPENCES_LIST_FAIL = "POST_DELETE_EXPENCES_LIST_FAIL"




// POST_ADD_SALES_DATA_REQUEST
export const POST_ADD_SALES_DATA_REQUEST = "POST_ADD_SALES_DATA_REQUEST"
export const POST_ADD_SALES_DATA_SUCCESS = "POST_ADD_SALES_DATA_SUCCESS"
export const POST_ADD_SALES_DATA_FAIL = "POST_ADD_SALES_DATA_FAIL"