// GET ALL PROJECT
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST'
export const GET_PROJECT_REQUEST_SUCCESS = 'GET_PROJECT_REQUEST_SUCCESS'
export const GET_PROJECT_REQUEST_FAIL = 'GET_PROJECT_REQUEST_FAIL'

// GET ALL LOG BY CODE
export const GET_ALL_LOG_BY_CODE_REQUEST = 'GET_ALL_LOG_BY_CODE_REQUEST'
export const GET_ALL_LOG_BY_CODE_SUCCESS = 'GET_ALL_LOG_BY_CODE_SUCCESS'
export const GET_ALL_LOG_BY_CODE_FAIL = 'GET_ALL_LOG_BY_CODE_FAIL'

// UPLOAD PROJECT
export const UPLOAD_NEW_PROJECT_REQUEST = 'UPLOAD_NEW_PROJECT_REQUEST'
export const UPLOAD_NEW_PROJECT_REQUEST_SUCCESS = 'UPLOAD_NEW_PROJECT_REQUEST_SUCCESS'
export const UPLOAD_NEW_PROJECT_REQUEST_FAIL = 'UPLOAD_NEW_PROJECT_REQUEST_FAIL'

// UPLOAD PROJECT 
export const UPLOAD_NEW_PROJECT_REQUEST_RESET = 'UPLOAD_NEW_PROJECT_REQUEST_RESET'

// GET LOG COUNT
export const GET_LOG_COUNT_REQUEST = 'GET_LOG_COUNT_REQUEST'
export const GET_LOG_COUNT_SUCCESS = 'GET_LOG_COUNT_SUCCESS'
export const GET_LOG_COUNT_FAIL = 'GET_LOG_COUNT_FAIL'

// GET LOG COUNT BY DATE
export const GET_LOG_COUNT_BY_DATE_REQUEST = 'GET_LOG_COUNT_BY_DATE_REQUEST'
export const GET_LOG_COUNT_BY_DATE_SUCCESS = 'GET_LOG_COUNT_BY_DATE_SUCCESS'
export const GET_LOG_COUNT_BY_DATE_FAIL = 'GET_LOG_COUNT_BY_DATE_FAIL'

// GET ERROR WRT OS
export const GET_ERROR_WRT_OS_REQUEST = 'GET_ERROR_WRT_OS_REQUEST'
export const GET_ERROR_WRT_OS_REQUEST_SUCCESS = 'GET_ERROR_WRT_OS_REQUEST_SUCCESS'
export const GET_ERROR_WRT_OS_REQUEST_FAIL = 'GET_ERROR_WRT_OS_REQUEST_FAIL'

// GET ERROR BY VERSION
export const GET_ERROR_COUNT_BY_VERSION_REQUEST = 'GET_ERROR_COUNT_BY_VERSION_REQUEST'
export const GET_ERROR_COUNT_BY_VERSION_REQUEST_SUCCESS = 'GET_ERROR_COUNT_BY_VERSION_REQUEST_SUCCESS'
export const GET_ERROR_COUNT_BY_VERSION_REQUEST_FAIL = 'GET_ERROR_COUNT_BY_VERSION_REQUEST_FAIL'

// GET DEVICE INFO
export const GET_DEVICE_INFO_REQUEST = 'GET_DEVICE_INFO_REQUEST'
export const GET_DEVICE_INFO_REQUEST_SUCCESS = 'GET_DEVICE_INFO_REQUEST_SUCCESS'
export const GET_DEVICE_INFO_REQUEST_FAIL = 'GET_DEVICE_INFO_REQUEST_FAIL'

// GET COUNT MSG OCCURRENCE
export const GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST = 'GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST'
export const GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST_SUCCESS = 'GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST_SUCCESS'
export const GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST_FAIL = 'GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST_FAIL'

// GET CRASH FREE USER
export const GET_CRASH_FREE_USERS_REQUEST = 'GET_CRASH_FREE_USERS_REQUEST'
export const GET_CRASH_FREE_USERS_REQUEST_SUCCESS = 'GET_CRASH_FREE_USERS_REQUEST_SUCCESS'
export const GET_CRASH_FREE_USERS_REQUEST_FAIL = 'GET_CRASH_FREE_USERS_REQUEST_FAIL'

// GET CRASH DATA
export const GET_CRASH_ANALYTICS_DATA_REQUEST = 'GET_CRASH_ANALYTICS_DATA_REQUEST'
export const GET_CRASH_ANALYTICS_DATA_REQUEST_SUCCESS = 'GET_CRASH_ANALYTICS_DATA_REQUEST_SUCCESS'
export const GET_CRASH_ANALYTICS_DATA_REQUEST_FAIL = 'GET_CRASH_ANALYTICS_DATA_REQUEST_FAIL'

// GET CRASH FREE USER
export const GET_CRASH_FREE_USERS_DATA_REQUEST = 'GET_CRASH_FREE_USERS_DATA_REQUEST'
export const GET_CRASH_FREE_USERS_DATA_REQUEST_SUCCESS = 'GET_CRASH_FREE_USERS_DATA_REQUEST_SUCCESS'
export const GET_CRASH_FREE_USERS_DATA_REQUEST_FAIL = 'GET_CRASH_FREE_USERS_DATA_REQUEST_FAIL'

// GET MODEL CODE
export const GET_MODEL_CODE_REQUEST = 'GET_MODEL_CODE_REQUEST'
export const GET_MODEL_CODE_SUCCESS = 'GET_MODEL_CODE_SUCCESS'
export const GET_MODEL_CODE_FAIL = 'GET_MODEL_CODE_FAIL'

// CRASH EMAIL
export const ADD_CRASH_EMAIL_REQUEST = 'ADD_CRASH_EMAIL_REQUEST'
export const ADD_CRASH_EMAIL_REQUEST_SUCCESS = 'ADD_CRASH_EMAIL_REQUEST_SUCCESS'
export const ADD_CRASH_EMAIL_REQUEST_FAIL = 'ADD_CRASH_EMAIL_REQUEST_FAIL'

// GET PROJECT BY CODE
export const GET_PROJECT_BY_CODE_REQUEST = 'GET_PROJECT_BY_CODE_REQUEST'
export const GET_PROJECT_BY_CODE_REQUEST_SUCCESS = 'GET_PROJECT_BY_CODE_REQUEST_SUCCESS'
export const GET_PROJECT_BY_CODE_REQUEST_FAIL = 'GET_PROJECT_BY_CODE_REQUEST_FAIL'

// GET CRASH PROJECT EMAIL 
export const GET_PROJECT_CRASH_EMAIL_REQUEST = 'GET_PROJECT_CRASH_EMAIL_REQUEST'
export const GET_PROJECT_CRASH_EMAIL_REQUEST_SUCCESS = 'GET_PROJECT_CRASH_EMAIL_REQUEST_SUCCESS'
export const GET_PROJECT_CRASH_EMAIL_REQUEST_FAIL = 'GET_PROJECT_CRASH_EMAIL_REQUEST_FAIL'

//GET DEVICE REGISTRATION DETAILS

export const GET_DEVICE_DETAILS_REQUEST = "GET_DEVICE_DETAILS_REQUEST"
export const GET_DEVICE_DETAILS_SUCCESS = "GET_DEVICE_DETAILS_SUCCESS"
export const GET_DEVICE_DETAILS_FAIL = "GET_DEVICE_DETAILS_FAIL"